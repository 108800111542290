import useSWR from 'swr';
import {
  getRequestDefault,
  postFeedbackReplyRequest,
  putMarkAsReadRequest,
} from './calls';

export function HandleGetAllFeedbackList(status = 'all', category = 'all') {
  const {data, error, isValidating, mutate} = useSWR(
    `/feedback/list/${status}/${category}/`,
    getRequestDefault,
    {
      fallbackData: [],
    },
  );

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate,
  };
}

export function HandleGetFeedbackCountRequest(
  status = 'all',
  category = 'all',
) {
  const {data, error, isValidating, mutate} = useSWR(
    `/feedback/read-unread/${status}/${category}/`,
    getRequestDefault,
    {
      fallbackData: [],
    },
  );

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate,
  };
}

export function handlePostFeedbackReplyRequest(
  requestBody,
  cb_success,
  cb_error,
  cb_finally,
) {
  postFeedbackReplyRequest(requestBody)
    .then(res => {
      cb_success(res);
    })
    .catch(err => {
      cb_error(err);
    })
    .finally(() => cb_finally());
}

export function handlePutMarkAsRead(
  feedbackId,
  cb_success,
  cb_error,
  cb_finally,
) {
  putMarkAsReadRequest(feedbackId)
    .then(res => {
      cb_success(res);
    })
    .catch(err => {
      cb_error(err);
    })
    .finally(() => cb_finally());
}
