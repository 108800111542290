import {Outlet} from 'react-router-dom';
import {Box} from '@mui/material';
import ResponsiveDrawer from '../components/general/SideBar';

export default function AdminRoot() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        height: 'max-content',
      }}>
      <ResponsiveDrawer role={'ADMIN'} />
      <Box
        component="main"
        sx={{
          p: 0,
          flexGrow: 1,
        }}>
        <Outlet />
      </Box>
    </Box>
  );
}
