import { Alert, Slide, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { closeSnackbar } from "../../stateManagement/features/common/commonSlice"

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />
}

function MySnackbar() {
  const {
    isSnackbarOpen,
    message,
    severity,
    autoHideDuration,
    vertical,
    horizontal
  } = useSelector(state => state.common)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeSnackbar())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      TransitionComponent={TransitionLeft}
      open={isSnackbarOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default MySnackbar
