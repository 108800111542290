import {configureStore} from '@reduxjs/toolkit';
import filterReducer from './features/filter/filterSlice';
import commonReducer from './features/common/commonSlice';
import feedbackReducer from './features/feedback/feedbackSlice';

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    common: commonReducer,
    feedback: feedbackReducer,
  },
});
