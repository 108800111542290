import { Backdrop, CircularProgress } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { hideBackdrop } from "../../stateManagement/features/common/commonSlice"

function MyBackdrop() {
  const { isBackdropVisible } = useSelector(state => state.common)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(hideBackdrop())
  }
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
      open={isBackdropVisible}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default MyBackdrop
