import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isSnackbarOpen: false,
  message: "",
  severity: "info",
  autoHideDuration: 4000,
  vertical: "top",
  horizontal: "right",
  isBackdropVisible: false
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    openSnackbar: (state, { payload }) => {
      state.isSnackbarOpen = true
      state.message = payload.message

      if (payload?.severity) {
        state.severity = payload.severity
      }
      if (payload?.autoHideDuration) {
        state.autoHideDuration = payload.autoHideDuration
      }
      if (payload?.vertical) {
        state.vertical = payload.vertical
      }
      if (payload?.horizontal) {
        state.horizontal = payload.horizontal
      }
    },
    closeSnackbar: state => {
      state.isSnackbarOpen = false
      state.message = ""
    },
    showBackdrop: state => {
      state.isBackdropVisible = true
    },
    hideBackdrop: state => {
      state.isBackdropVisible = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { openSnackbar, closeSnackbar, showBackdrop, hideBackdrop } =
  commonSlice.actions

export default commonSlice.reducer
