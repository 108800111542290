import {api, apiHost, apiNoAuth} from './axiosApi';

export function getRequestDefault(url) {
  return api.get(url);
}

export function getRequestHostDefault(url) {
  return apiHost.get(url);
}

export function getRequestNoAuthDefault(url) {
  return apiNoAuth.get(url);
}

export function postFeedbackReplyRequest(feedbackObj) {
  return api.post('feedback-reply/', feedbackObj);
}

export function putMarkAsReadRequest(feedbackId) {
  return api.put(`feedback/mark-read/${feedbackId}/`, feedbackId);
}
