import {createContext, useMemo} from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';

const ColorModeContext = createContext({toggleColorMode: () => {}});

const ColorProvider = ({children}) => {
  const colorMode = useMemo(() => ({}), []);

  const customColors = useMemo(
    () => ({
      purple: {
        'Baby-Purple': '#BCBEEA',
        100: '#9C95B9',
      },
      red: {
        400: '#F80303',
        500: '#EA4335',
      },
      white: {
        100: '#F7F7F7',
        200: '#F6F6F6',
        300: '#E5E3DF',
      },
      grey: {
        'Light-Grey': '#7F7F88',
        Grey: '#555555',
        50: '#F1F1F5',
        100: '#D9D9D9',
        110: '#E9E9E9',
        '110-30': '#E9E9E94D',
        200: '#E2E2EA',
        300: '#92929D',
        400: '#B5B5BE',
        500: '#696974',
        700: '#44444F',
      },
      green: {
        400: '#34A853',
      },
      blue: {
        900: '#171725',
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        shape: {
          borderRadius: 10,
        },
        typography: {fontFamily: 'Roboto, Montserrat, sans-serif'},
        components: {
          MuiButton: {
            styleOverrides: {
              root: ({theme}) => ({
                ...{
                  transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.standard,
                  }),
                  '&:hover': {
                    transform: `scale(1.05)`,
                  },
                  borderRadius: 30,
                },
              }),
            },
          },
          MuiTab: {
            styleOverrides: {
              root: () => ({
                ...{
                  borderColor: 'green',
                },
              }),
            },
          },
        },
        palette: {
          primary: {
            main: `rgba(228, 228, 228, 0.1)`,
            dark: '#292939',
            light: `#E1E2F2`,
          },
          divider: '#292939',
          text: {
            primary: '#464E5F',
            secondary: '#959595',
          },
          background: {
            default: '#F7F7F7',
          },
          customColors: customColors,
        },
      }),
    [customColors],
  );

  // 25
  theme.shadows.push(
    'rgba(0, 0, 0, 0.05) 0px 20px 27px 0px;rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem.css-1ikvzz0',
  );
  // 26
  theme.shadows.push('0px -2px 13px rgba(0, 0, 0, 0.25)');
  // 27
  theme.shadows.push(
    '0px 3px 7px rgba(0, 0, 0, 0.08), 0px 3px 7px rgba(0, 0, 0, 0.08), 0px 3px 7px rgba(0, 0, 0, 0.08)',
  );
  // 28
  theme.shadows.push('0px 3px 7px rgba(0, 0, 0, 0.08)');

  // 29
  theme.shadows.push('0px 3px 7px rgba(0, 0, 0, 0.25)');

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export {ColorProvider, ColorModeContext};
