import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  Link,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useNavigate, useParams} from 'react-router-dom';
import search2Icon from '../../assets/icons/search2.svg';
import {
  HandleGetAllFeedbackList,
  HandleGetFeedbackCountRequest,
} from '../../services/callsHandler';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {STATUSES} from '../../services/constants';
import moment from 'moment/moment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorComponent from '../../components/general/ErrorComponent';
import SingleLineSkeleton from '../../components/skeletons/SingleLineSkeleton';
import ViewFeedbackModal from '../../components/modals/ViewFeedbackModal';
import WriteFeedbackModal from '../../components/modals/WriteFeedbackModal';
import {useDispatch} from 'react-redux';
import {setFeedbackObjectValue} from '../../stateManagement/features/feedback/feedbackSlice';

function FeedbackRecord({item, handleViewClick}) {
  const statusArray = useMemo(
    () => STATUSES.filter(status => status.id === item.status),
    [item.status],
  );

  const statusObject = useMemo(
    () => (statusArray && statusArray.length > 0 ? statusArray[0] : undefined),
    [statusArray],
  );

  return (
    <Grid
      container
      columns={17}
      sx={{
        alignItems: 'center',
        backgroundColor: ({palette}) =>
          item?.is_read
            ? palette.customColors.grey['110-30']
            : palette.customColors.grey[110],
        borderRadius: '15px',
        paddingX: 2,
        paddingY: 0.5,
      }}>
      <Grid item xs={1}>
        <Checkbox checkedIcon={<CheckBoxIcon sx={{color: 'primary.dark'}} />} />
      </Grid>
      <Grid item xs={1}>
        <Button
          sx={{
            padding: 0,
            margin: 0,
          }}
          onClick={() => handleViewClick(item)}>
          <Typography
            fontSize={14}
            fontWeight={600}
            sx={{
              color: ({palette}) => palette.customColors.blue[900],
              ':hover': {
                textDecoration: 'underline',
              },
            }}>
            {item.id}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Typography
          fontSize={14}
          fontWeight={500}
          sx={{color: ({palette}) => palette.customColors.grey[700]}}>
          {item.title}
        </Typography>
      </Grid>
      <Grid item xs={6} display={'flex'} justifyContent={'center'}>
        <Typography
          fontSize={14}
          fontWeight={400}
          sx={{color: ({palette}) => palette.customColors.grey[300]}}>
          {item.message}
        </Typography>
      </Grid>
      <Grid item xs={2} display={'flex'} justifyContent={'center'}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Box
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50px',
              backgroundColor: statusObject?.color,
            }}
          />
          <Typography
            fontSize={14}
            fontWeight={400}
            sx={{color: ({palette}) => palette.customColors.grey[300]}}>
            {statusObject?.label}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} display={'flex'} justifyContent={'center'}>
        <Typography
          fontSize={14}
          fontWeight={400}
          sx={{color: ({palette}) => palette.customColors.grey[300]}}>
          {moment(new Date(item.created)).format('hh:mmA')}
        </Typography>
      </Grid>
      <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
        <Typography
          fontSize={14}
          fontWeight={400}
          sx={{color: ({palette}) => palette.customColors.grey[300]}}>
          {moment(new Date(item.created)).format('MMM DD,YYYY')}
        </Typography>
      </Grid>
    </Grid>
  );
}

function Feedback() {
  const [status, setStatus] = useState('all');
  const [category, setCategory] = useState('all');
  const [backendErrors, setBackendErrors] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openWriteModal, setOpenWriteModal] = useState(false);

  let {statusStr, categoryStr} = useParams();

  const {
    data: feedbackData,
    isLoading: feedbackIsLoading,
    isError: feedbackIsError,
    isValidating: feedbackIsValidating,
    mutate: feedbackMutate,
  } = HandleGetAllFeedbackList(status, category);

  const {data: feedbackCountData, mutate: feedbackCountMutate} =
    HandleGetFeedbackCountRequest('all', 'all');

  const readCount = useMemo(
    () =>
      feedbackCountData && feedbackCountData?.message === 'Ok'
        ? feedbackCountData.read_count
        : 0,
    [feedbackCountData],
  );

  const unreadCount = useMemo(
    () =>
      feedbackCountData && feedbackCountData?.message === 'Ok'
        ? feedbackCountData.unread_count
        : 0,
    [feedbackCountData],
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewClick = useCallback(
    item => {
      setOpenViewModal(true);
      dispatch(setFeedbackObjectValue(item));
    },
    [dispatch],
  );

  const handleReplyClick = useCallback(() => {
    setOpenViewModal(false);
    setOpenWriteModal(true);
  }, []);

  useEffect(() => {
    function checkData() {
      if (feedbackData) {
        debugger;
      }
    }
    checkData();
  }, [feedbackData]);

  useEffect(() => {
    function initialize() {
      setCategory(categoryStr);
      setStatus(statusStr);
    }
    initialize();
  }, [statusStr, categoryStr]);

  useEffect(() => {
    if (feedbackIsError) {
      setBackendErrors({
        response: feedbackIsError?.response?.data,
        fields: [],
        setError: () => {},
      });
    }
  }, [feedbackIsError]);

  const handleCheckStatus = useCallback(
    value => {
      if (status === value) {
        setStatus('all');
        navigate(`/administration/feedback/${category}/all`);
      } else {
        setStatus(value);
        navigate(`/administration/feedback/${category}/${value}`);
      }
    },
    [status, category, setStatus, navigate],
  );

  const handleCheckCategory = useCallback(
    value => {
      if (category === value) {
        setCategory('all');
        navigate(`/administration/feedback/all/${status}`);
      } else {
        setCategory(value);
        navigate(`/administration/feedback/${value}/${status}`);
      }
    },
    [category, status, setCategory, navigate],
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => navigate('/administration')}>
      Dashboard
    </Link>,
    <Typography key="3" color="text.primary">
      Feedback
    </Typography>,
  ];
  return (
    <Stack sx={{paddingY: 3, paddingX: 3}}>
      <ViewFeedbackModal
        open={openViewModal}
        handleClose={() => setOpenViewModal(false)}
        handleReplyClick={handleReplyClick}
        feedbackMutate={feedbackMutate}
        feedbackCountMutate={feedbackCountMutate}
      />
      <WriteFeedbackModal
        open={openWriteModal}
        handleClose={() => setOpenWriteModal(false)}
      />
      <Toolbar sx={{height: '80px'}} />
      <Breadcrumbs
        sx={{alignSelf: 'flex-end'}}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <Stack
        direction={'row'}
        alignItems={'center'}
        width={'100%'}
        justifyContent={'space-between'}>
        <Stack>
          <Typography fontSize={36} fontWeight={600}>
            Feedback
          </Typography>
          <Stack direction={'row'} spacing={2} alignItems={'center'} ml={-1}>
            <Button onClick={() => handleCheckCategory('all')}>
              <Typography
                color={'primary.dark'}
                textTransform={'capitalize'}
                fontWeight={category === 'all' ? '700' : 400}>
                {`All messages (${readCount + unreadCount})`}
              </Typography>
            </Button>
            <Button onClick={() => handleCheckCategory('read')}>
              <Typography
                color={'primary.dark'}
                textTransform={'capitalize'}
                fontWeight={category === 'read' ? '700' : 400}>
                {`Read (${readCount})`}
              </Typography>
            </Button>
            <Button onClick={() => handleCheckCategory('unread')}>
              <Typography
                color={'primary.dark'}
                textTransform={'capitalize'}
                fontWeight={category === 'unread' ? '700' : 400}>
                {`Unread(${unreadCount})`}
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Button variant="contained" sx={{backgroundColor: 'primary.dark'}}>
            <Typography color={'common.white'} textTransform={'capitalize'}>
              Suggest A Feature
            </Typography>
          </Button>
          <Button
            variant="text"
            sx={{
              paddingX: 2,
              border: ({palette}) => `0.5px solid ${palette.primary.dark}`,
              backgroundColor: 'common.white',
              borderWidth: 2,
              '&:hover': {backgroundColor: 'common.white'},
            }}>
            <Typography color={'primary.dark'} textTransform={'capitalize'}>
              Add New Ticket
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{
          backgroundColor: 'common.white',
          flexGrow: 1,
          width: '100%',
          height: '100%',
          mt: 2,
          p: 2,
        }}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Paper
            elevation={0}
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 650,
              bgcolor: 'transparent',
              borderRadius: '10px',
              border: ({palette}) =>
                `1px solid ${palette.customColors.grey[200]}`,
            }}>
            <IconButton type="button" sx={{p: '10px'}} aria-label="search">
              <img src={search2Icon} alt={'search2Icon'} />
            </IconButton>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                input: {
                  // color: 'white',
                  // '&::placeholder': {
                  //   color: 'white',
                  // },
                },
              }}
              placeholder="Find Ticket"
              inputProps={{'aria-label': 'search'}}
              color={'common.white'}
            />
          </Paper>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography>
              Tickets {feedbackData ? `(${feedbackData?.total_count})` : ''}
            </Typography>
            {(feedbackIsLoading || feedbackIsValidating) && (
              <CircularProgress size={14} sx={{color: 'primary.dark'}} />
            )}
          </Stack>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Checkbox
              disabled={feedbackIsLoading || feedbackIsValidating}
              checked={status === 'all'}
              checkedIcon={<CheckBoxIcon sx={{color: 'primary.dark'}} />}
              onChange={() => handleCheckStatus('all')}
            />
            <Typography fontSize={12}>All</Typography>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Checkbox
              disabled={feedbackIsLoading || feedbackIsValidating}
              checked={status === 'on_hold'}
              checkedIcon={<CheckBoxIcon sx={{color: 'primary.dark'}} />}
              onChange={() => handleCheckStatus('on_hold')}
            />
            <Typography fontSize={12}>On Hold</Typography>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Checkbox
              disabled={feedbackIsLoading || feedbackIsValidating}
              checked={status === 'in_progress'}
              checkedIcon={<CheckBoxIcon sx={{color: 'primary.dark'}} />}
              onChange={() => handleCheckStatus('in_progress')}
            />
            <Typography fontSize={12}>In Progress</Typography>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Checkbox
              disabled={feedbackIsLoading || feedbackIsValidating}
              checked={status === 'completed'}
              checkedIcon={<CheckBoxIcon sx={{color: 'primary.dark'}} />}
              onChange={() => handleCheckStatus('completed')}
            />
            <Typography fontSize={12}>Completed</Typography>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          {(!feedbackData || feedbackIsLoading) && !feedbackIsError ? (
            <Box width={'100%'}>
              <SingleLineSkeleton styles={{height: '44px'}} />
            </Box>
          ) : feedbackIsError ? (
            <ErrorComponent {...backendErrors} />
          ) : feedbackData?.data?.length === 0 ? (
            <Typography fontSize={'20px'} fontWeight={'600'} align={'center'}>
              No Data Found
            </Typography>
          ) : (
            feedbackData?.data?.map(item => (
              <FeedbackRecord
                key={item.id}
                item={item}
                handleViewClick={handleViewClick}
              />
            ))
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Feedback;
