import axios from 'axios';
import config from './Config';

const api = axios.create({
  baseURL: `${config.HOST}api/v1/`,
});

const apiHost = axios.create({
  baseURL: `${config.HOST}`,
});

const apiNoAuth = axios.create({
  baseURL: `${config.HOST}api/v1/`,
});

export {api, apiHost, apiNoAuth};
