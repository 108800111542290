import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import ErrorComponent from '../general/ErrorComponent';
import {Stack} from '@mui/system';
import closeIcon from '../../assets/icons/close.svg';
import attachmentIcon from '../../assets/icons/attachment.svg';
import emojiIcon from '../../assets/icons/emoji.svg';
import {MuiChipsInput} from 'mui-chips-input';
import EmojiPicker from 'emoji-picker-react';
import {handlePostFeedbackReplyRequest} from '../../services/callsHandler';
import {openSnackbar} from '../../stateManagement/features/common/commonSlice';
import {setFeedbackObjectValue} from '../../stateManagement/features/feedback/feedbackSlice';

const drawerWidth = 253;

const style = {
  position: 'absolute',
  top: '50%',
  left: `calc(100vw - ${drawerWidth}px)`,
  transform: 'translate(-95%, -50%)',
  width: {sm: 550, md: 750, lg: 1100},
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  paddingY: 5,
  paddingX: 4,
};

function WriteFeedbackModal({open, handleClose}) {
  const [loading, setLoading] = useState(false);
  const [isCcVisible, setIsCcVisible] = useState(false);
  const [isBccVisible, setIsBccVisible] = useState(false);

  const [backendErrors, setBackendErrors] = useState(null);
  const [chips, setChips] = useState([]);
  const [ccChips, setCcChips] = useState([]);
  const [bccChips, setBccChips] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const {feedbackObject: feedback} = useSelector(state => state.feedback);

  const dispatch = useDispatch();

  function clear() {
    setIsCcVisible(false);
    setIsBccVisible(false);
    setBackendErrors(null);
    setSubject('');
    setMessage('');
    setChips([]);
    setCcChips([]);
    setBccChips([]);
  }

  useEffect(() => {
    function initialize() {
      setChips(prev => [...prev, [feedback?.email]]);
    }
    clear();
    initialize();
  }, [open, feedback]);

  const openEmojiPicker = Boolean(anchorEl);
  const handleClickEmoji = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    handleClose();
    dispatch(setFeedbackObjectValue(null));
  };

  const handleCloseEmojiPicker = () => {
    setAnchorEl(null);
  };

  const handleChange = newChips => {
    setChips(newChips);
  };

  const handleChangeCc = newChips => {
    setCcChips(newChips);
  };

  const handleChangeBcc = newChips => {
    setBccChips(newChips);
  };

  const handleSelectEmoji = emoji => {
    setMessage(`${message}${emoji.emoji}`);
  };

  const handleSendFeedbackReply = () => {
    setLoading(true);
    debugger;
    handlePostFeedbackReplyRequest(
      {subject, receiver: chips.join(','), message},
      () => {
        clear();
        handleCloseModal();
        dispatch(
          openSnackbar({
            message: 'Email sent successfully',
            severity: 'success',
          }),
        );
      },
      err => {
        dispatch(
          openSnackbar({
            message: 'Failed to send the email',
            severity: 'error',
          }),
        );
        setBackendErrors({
          response: err?.response?.data ? err?.response?.data : err?.message,
          fields: Object.keys({}),
          setError: () => {},
        });
      },
      () => {
        setLoading(false);
      },
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Typography
            fontSize={'18px'}
            fontWeight={'500'}
            width={'100%'}
            color={'primary.dark'}
            ml={1}
            mb={2}>
            Write a Message
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <img src={closeIcon} alt="closeIcon" />
          </IconButton>
        </Stack>

        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          sx={{
            borderBottom: ({palette}) =>
              `1px solid ${palette.customColors.grey[50]}`,
          }}>
          <Typography
            sx={{color: ({palette}) => palette.customColors.grey[300]}}
            fontSize={'14px'}
            fontWeight={'400'}>
            Send to
          </Typography>
          <MuiChipsInput
            variant="standard"
            value={chips}
            onChange={handleChange}
            sx={{
              flexGrow: 1,
              '& .MuiInput-root': {
                '&:before, :after, :hover:not(.Mui-disabled):before': {
                  borderBottom: 0,
                },
              },
            }}
          />
          {!isCcVisible && (
            <IconButton onClick={() => setIsCcVisible(true)}>
              <Typography
                sx={{color: ({palette}) => palette.customColors.grey[500]}}
                fontSize={'14px'}
                fontWeight={'500'}>
                Cc
              </Typography>
            </IconButton>
          )}
          {!isBccVisible && (
            <IconButton onClick={() => setIsBccVisible(true)}>
              <Typography
                sx={{color: ({palette}) => palette.customColors.grey[500]}}
                fontSize={'14px'}
                fontWeight={'500'}>
                Bcc
              </Typography>
            </IconButton>
          )}
        </Stack>
        {isCcVisible && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={{
              borderBottom: ({palette}) =>
                `1px solid ${palette.customColors.grey[50]}`,
            }}>
            <Typography
              sx={{color: ({palette}) => palette.customColors.grey[300]}}
              fontSize={'14px'}
              fontWeight={'400'}>
              Cc
            </Typography>
            <MuiChipsInput
              variant="standard"
              value={ccChips}
              onChange={handleChangeCc}
              sx={{
                flexGrow: 1,
                '& .MuiInput-root': {
                  '&:before, :after, :hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                  },
                },
              }}
            />
          </Stack>
        )}
        {isBccVisible && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={{
              borderBottom: ({palette}) =>
                `1px solid ${palette.customColors.grey[50]}`,
            }}>
            <Typography
              sx={{color: ({palette}) => palette.customColors.grey[300]}}
              fontSize={'14px'}
              fontWeight={'400'}>
              Bcc
            </Typography>
            <MuiChipsInput
              variant="standard"
              value={bccChips}
              onChange={handleChangeBcc}
              sx={{
                flexGrow: 1,
                '& .MuiInput-root': {
                  '&:before, :after, :hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                  },
                },
              }}
            />
          </Stack>
        )}

        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          sx={{
            borderBottom: ({palette}) =>
              `1px solid ${palette.customColors.grey[50]}`,
            mt: 2,
          }}>
          <Typography
            sx={{color: ({palette}) => palette.customColors.grey[300]}}
            fontSize={'14px'}
            fontWeight={'400'}>
            Subject
          </Typography>
          <TextField
            value={subject}
            onChange={e => setSubject(e.target.value)}
            variant="standard"
            sx={{
              '& .MuiInput-root': {
                '&:before, :after, :hover:not(.Mui-disabled):before': {
                  borderBottom: 0,
                },
              },
            }}
          />
        </Stack>

        <Box sx={{minHeight: '300px'}}>
          <TextField
            value={message}
            onChange={e => setMessage(e.target.value)}
            multiline
            rows={9}
            variant="standard"
            sx={{
              height: '100%',
              width: '100%',
              mt: 2,
              '& .MuiInput-root': {
                '&:before, :after, :hover:not(.Mui-disabled):before': {
                  borderBottom: 0,
                },
              },
            }}
          />
        </Box>

        {backendErrors !== null ? <ErrorComponent {...backendErrors} /> : ''}
        <Box
          sx={{
            borderTop: ({palette}) =>
              `1px solid ${palette.customColors.grey[50]}`,
          }}
        />

        <Stack direction={'row'} alignItems={'center'} sx={{mt: 3}}>
          <Button
            onClick={handleSendFeedbackReply}
            variant="contained"
            sx={{
              backgroundColor: 'primary.dark',
              borderRadius: '10px',
              mr: 3,
            }}
            endIcon={
              loading ? (
                <CircularProgress
                  size={18}
                  sx={{mr: 1, color: 'common.white'}}
                />
              ) : (
                <></>
              )
            }>
            {loading ? (
              ''
            ) : (
              <Typography
                color={'common.white'}
                textTransform={'capitalize'}
                fontWeight={'500'}
                fontSize={10}>
                Send Message
              </Typography>
            )}
          </Button>
          <IconButton sx={{position: 'relative'}}>
            <img src={attachmentIcon} alt="attachmentIcon" />
          </IconButton>

          <IconButton onClick={handleClickEmoji}>
            <img src={emojiIcon} alt="emojiIcon" />
          </IconButton>
          <Dialog
            disableEscapeKeyDown
            open={openEmojiPicker}
            onClose={handleCloseEmojiPicker}>
            <DialogContent>
              <EmojiPicker onEmojiClick={handleSelectEmoji} />
            </DialogContent>
          </Dialog>
        </Stack>
      </Box>
    </Modal>
  );
}

export default WriteFeedbackModal;
