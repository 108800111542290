import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  search: ""
}

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.search = action.payload
    },
    resetSearchValue: state => {
      state.search = ""
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSearchValue, resetSearchValue } = filterSlice.actions

export default filterSlice.reducer
