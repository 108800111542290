import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import ErrorPage from './general/error-page';
import AdminRoot from './adminRoot';
import Feedback from './feedback/Feedback';
import {Fragment, useEffect, useState} from 'react';
import {Box, CircularProgress} from '@mui/material';

const router = createBrowserRouter([
  {
    path: '*',
    element: <ErrorPage />,
  },
  {
    path: '/administration',
    element: <AdminRoot />,
    children: [
      {
        path: 'feedback/:categoryStr/:statusStr',
        element: <Feedback />,
      },
      {
        path: 'feedback/*',
        element: <Navigate to={'all/all'} />,
      },
    ],
  },
]);

export default function Router() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => setLoading(false), 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Box
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <RouterProvider router={router} />
      )}
    </Fragment>
  );
}
