import './App.css';
import {Suspense} from 'react';
import {Box, CircularProgress, CssBaseline} from '@mui/material';
import {ColorProvider} from './contexts/Color';
import Router from './routes/router';
import MySnackbar from './components/general/MySnackbar';
import MyBackdrop from './components/general/MyBackdrop';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  return (
    <Suspense
      fallback={
        <Box
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress color="inherit" />
        </Box>
      }>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ColorProvider>
          <CssBaseline enableColorScheme />
          <MySnackbar />
          <MyBackdrop />
          <Router />
        </ColorProvider>
      </LocalizationProvider>
    </Suspense>
  );
}

export default App;
