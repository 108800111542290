import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  feedbackObject: null,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedbackObjectValue: (state, action) => {
      state.feedbackObject = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setFeedbackObjectValue} = feedbackSlice.actions;

export default feedbackSlice.reducer;
