import {Stack, Typography} from '@mui/material';
import {Link, useRouteError} from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Stack
      component="main"
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'primary.light',
      }}>
      <Typography variant="h1" fontWeight={'500'} color={'primary.dark'}>
        404!
      </Typography>
      <Typography variant="h6" fontWeight={'500'} color={'primary.main'}>
        This page not found.
      </Typography>
      <Typography component={Link} to={'/administration'} sx={{mt: 1}}>
        Goto Home
      </Typography>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </Stack>
  );
}
