import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import ErrorComponent from '../general/ErrorComponent';
import {Stack} from '@mui/system';
import closeIcon from '../../assets/icons/close.svg';
import imageIcon from '../../assets/icons/image.svg';
import moment from 'moment';
import {handlePutMarkAsRead} from '../../services/callsHandler';
import {openSnackbar} from '../../stateManagement/features/common/commonSlice';
import {setFeedbackObjectValue} from '../../stateManagement/features/feedback/feedbackSlice';

const drawerWidth = 253;

const style = {
  position: 'absolute',
  top: '50%',
  left: `calc(100vw - ${drawerWidth}px)`,
  transform: 'translate(-95%, -50%)',
  width: {sm: 550, md: 750, lg: 1100},
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  paddingY: 5,
  paddingX: 4,
};

function ViewFeedbackModal({
  open,
  handleClose,
  handleReplyClick,
  feedbackMutate,
  feedbackCountMutate,
}) {
  const [loading, setLoading] = useState(false);
  const [backendErrors, setBackendErrors] = useState(null);
  const {feedbackObject: feedback} = useSelector(state => state.feedback);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    handleClose();
    dispatch(setFeedbackObjectValue(null));
  };

  useEffect(() => {
    function markAsRead() {
      if (open && !feedback?.is_read) {
        handlePutMarkAsRead(
          feedback?.id,
          () => {
            dispatch(
              openSnackbar({
                message: 'Marked as read successfully',
                severity: 'success',
              }),
            );
            feedbackMutate();
            feedbackCountMutate();
          },
          err => {
            dispatch(
              openSnackbar({
                message: 'Failed to mark this feedback as read',
                severity: 'error',
              }),
            );
            setBackendErrors({
              response: err?.response?.data
                ? err?.response?.data
                : err?.message,
              fields: Object.keys({}),
              setError: () => {},
            });
          },
          () => {
            setLoading(false);
          },
        );
      }
    }

    markAsRead();
  }, [open, feedback, dispatch, feedbackCountMutate, feedbackMutate]);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Typography
            fontSize={'18px'}
            fontWeight={'500'}
            width={'100%'}
            color={'primary.dark'}>
            Read Messsage
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <img src={closeIcon} alt="closeIcon" />
          </IconButton>
        </Stack>

        <Stack
          my={5}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <img src={imageIcon} alt="imageIcon" style={{width: '50px'}} />
            <Stack>
              <Typography
                color={'primary.dark'}
                fontSize={'16px'}
                fontWeight={'600'}>
                Tom Gravesen
              </Typography>
              <Typography
                sx={{color: ({palette}) => palette.customColors.grey[400]}}
                fontSize={'14px'}
                fontWeight={'400'}>
                {feedback?.email}
              </Typography>
            </Stack>
          </Stack>

          <Typography fontSize={'16px'} fontWeight={'400'}>
            {moment(new Date(feedback?.created)).format('MM/DD/YYYY hh:mm A')}
          </Typography>
        </Stack>

        <Typography color={'primary.dark'} fontSize={24} fontWeight={600}>
          {feedback?.title}
        </Typography>

        <Box sx={{minHeight: '200px'}}>
          <Typography
            mt={2}
            fontSize={16}
            fontWeight={400}
            sx={{color: ({palette}) => palette.customColors.grey[700]}}>
            {feedback?.message}
          </Typography>
        </Box>

        {backendErrors !== null ? <ErrorComponent {...backendErrors} /> : ''}
        <Box
          sx={{
            borderTop: ({palette}) =>
              `1px solid ${palette.customColors.grey[50]}`,
          }}
        />

        <Button
          onClick={handleReplyClick}
          variant="contained"
          sx={{
            backgroundColor: 'primary.dark',
            borderRadius: '10px',
            mt: 3,
          }}
          endIcon={
            loading ? (
              <CircularProgress size={18} sx={{mr: 1, color: 'common.white'}} />
            ) : (
              <></>
            )
          }>
          {loading ? (
            ''
          ) : (
            <Typography
              color={'common.white'}
              textTransform={'capitalize'}
              fontWeight={'500'}
              fontSize={10}>
              Reply
            </Typography>
          )}
        </Button>
      </Box>
    </Modal>
  );
}

export default ViewFeedbackModal;
