export const STATUSES = [
  {
    id: 'on_hold',
    label: 'on Hold',
    color: '#5596A5',
  },
  {
    id: 'completed',
    label: 'Completed',
    color: '#20E03E',
  },
  {
    id: 'in_progress',
    label: 'In progress',
    color: '#92929D',
  },
];
