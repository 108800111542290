import {
  AppBar,
  Button,
  IconButton,
  Input,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

import {motion, useCycle} from 'framer-motion';
import {useEffect, useMemo, useState} from 'react';
import logoIcon from '../../assets/icons/emotions.svg';
import {NavLink} from 'react-router-dom';
import homeIcon from '../../assets/icons/home.svg';
import usersIcon from '../../assets/icons/users.svg';
import databaseIcon from '../../assets/icons/database.svg';
import feedbackIcon from '../../assets/icons/feedback.svg';
import collapseIcon from '../../assets/icons/collapse.svg';
import searchIcon from '../../assets/icons/search.svg';
import mailIcon from '../../assets/icons/mail.svg';
import ringIcon from '../../assets/icons/ring.svg';
import arrowDownIcon from '../../assets/icons/arrowDown.svg';
import {bgcolor} from '@mui/system';
// import LogoutModal from '../modals/LogoutModal';

const buttons = [
  {
    to: '/administration',
    label: 'Home',
    icon: homeIcon,
  },
  {
    to: 'users',
    label: 'All Users',
    icon: usersIcon,
  },
  {
    to: 'database',
    label: 'Database',
    icon: databaseIcon,
  },
  {
    to: 'feedback',
    label: 'Feedback',
    icon: feedbackIcon,
  },
];

const drawerWidth = 253;
const logoWidth = 57.9;

function ResponsiveDrawer(props) {
  const {window, role} = props;
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [isLogoutOpen, toggleLogoutOpen] = useCycle(false, true);
  const [isMenuOpen, toggleMenuOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      toggleMenuOpen(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {type: 'spring', stiffness: 300, damping: 24},
    },
    closed: {opacity: 0, y: 20, transition: {duration: 0.2}},
  };

  const drawer = (
    <Box
      sx={{
        height: '100%',
        backgroundColor: 'primary.dark',
      }}>
      <Stack
        sx={{
          height: '100%',
          paddingBottom: 6,
        }}>
        <Box
          sx={{
            marginBottom: 7,
            marginTop: 2,
            marginX: 1,
          }}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}>
            <Stack direction={'row'} alignItems={'center'}>
              <img src={logoIcon} alt="logo" width={logoWidth} />
              <Typography
                fontSize={'22px'}
                fontWeight={700}
                sx={{
                  color: ({palette}) => palette.customColors.white[300],
                }}>
                PULSE
              </Typography>
            </Stack>
            <IconButton>
              <img src={collapseIcon} alt={collapseIcon} />
            </IconButton>
          </Stack>
        </Box>
        <Stack sx={{flexGrow: 1}} justifyContent={'space-between'}>
          <motion.nav
            initial={false}
            animate={isMenuOpen ? 'open' : 'closed'}
            style={{
              width: '100%',
            }}>
            <motion.ul
              variants={{
                open: {
                  clipPath: 'inset(0% 0% 0% 0% round 10px)',
                  transition: {
                    type: 'spring',
                    bounce: 0,
                    duration: 0.7,
                    delayChildren: 0.3,
                    staggerChildren: 0.05,
                  },
                },
                closed: {
                  clipPath: 'inset(10% 50% 90% 50% round 10px)',
                  transition: {
                    type: 'spring',
                    bounce: 0,
                    duration: 0.3,
                  },
                },
              }}
              style={{
                pointerEvents: isMenuOpen ? 'auto' : 'none',
                padding: 0,
              }}>
              {buttons.map((item, index) => (
                <motion.li key={item.to} variants={itemVariants}>
                  <Button
                    component={NavLink}
                    end
                    to={item.to}
                    sx={{
                      mt: index === 0 ? 0 : 3,
                      width: '88%',
                      marginLeft: 2,
                      borderRadius: '12px',
                      paddingY: 1.5,
                      '&.active': {
                        backgroundColor: 'primary.main',
                      },
                      '&:hover': {
                        backgroundColor: 'primary.main',
                      },
                    }}>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      sx={{width: '100%', paddingLeft: 4}}>
                      <img src={item.icon} alt={item.label} />
                      <Typography
                        color={'common.white'}
                        textTransform={'capitalize'}
                        fontWeight={'400'}
                        fontSize={16}
                        ml={2}
                        sx={{
                          color: ({palette}) =>
                            palette.customColors.grey['Light-Grey'],
                          '&.active': {
                            fontWeight: '700',
                            color: 'common.white',
                          },
                        }}>
                        {item.label}
                      </Typography>
                    </Stack>
                  </Button>
                </motion.li>
              ))}
            </motion.ul>
          </motion.nav>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Box sx={{display: 'flex'}}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
          },
          ml: {
            sm: `${drawerWidth}px`,
          },
          paddingX: 4,
          paddingBottom: 1,
          height: '80px',
          bgcolor: 'primary.dark',
        }}>
        <Toolbar sx={{width: '100%', height: 'max-content'}}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{width: '100%'}}>
            <Paper
              elevation={0}
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                bgcolor: 'transparent',
              }}>
              <IconButton type="button" sx={{p: '10px'}} aria-label="search">
                <img src={searchIcon} alt={'searchIcon'} />
              </IconButton>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  input: {
                    color: 'white',
                    '&::placeholder': {
                      color: 'white',
                    },
                  },
                }}
                placeholder="Search"
                inputProps={{'aria-label': 'search'}}
                color={'common.white'}
              />
            </Paper>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <IconButton>
                <img src={mailIcon} alt={'mailIcon'} />
              </IconButton>
              <IconButton>
                <img src={ringIcon} alt={'ringIcon'} />
              </IconButton>
              <IconButton>
                <img src={arrowDownIcon} alt={'arrowDownIcon'} />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {/* <LogoutModal open={isLogoutOpen} handleClose={toggleLogoutOpen} /> */}
      <Box
        component="nav"
        sx={{
          width: {sm: drawerWidth},
          flexShrink: {sm: 0},
        }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          elevation={25}
          container={container}
          variant="temporary"
          open={isOpen}
          onClose={toggleOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              height: `calc(100% - 30px)`,
              mx: 2,
              my: 2,
              borderRadius: '10px',
              bgcolor: 'primary.dark',
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
            backgroundColor: '#000',
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
